import "./src/styles/global.css"
import React from "react"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

import Auth0ProviderWithHistory from "./src/auth/auth0-provider-with-history"
import { UserProvider } from "./src/context/UserAuth"

export const wrapRootElement = ({ element, props }) => {
  let test = false

  return (
    <Auth0ProviderWithHistory>
      <UserProvider value={{ test }}>{element}</UserProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Auth0ProviderWithHistory>
  )
}
