import axios from "axios"
import keys from "../config/keys"
import { getPurchaseDate, getExpireDate } from "./helper"

export const updateUserPlan = (_userid, _plan) => {
  let options = {
    method: "PATCH",
    url: `https://${process.env.GATSBY_AUTH_0_DOMAIN}/api/v2/users/${_userid}`,
    headers: {
      authorization: `Bearer ${process.env.GATSBY_TOKEN}`,
      "content-type": "application/json",
    },
    data: {
      app_metadata: {
        plan: _plan,
        purchased_date: getPurchaseDate(),
        expiration_date: getExpireDate(5),
      },
    },
  }

  return axios.request(options)
}

export const getUserData = async _userid => {
  const response = await axios({
    method: "GET",
    url: `https://${process.env.GATSBY_AUTH_0_DOMAIN}/api/v2/users/${_userid}`,
    headers: {
      authorization: `Bearer ${process.env.GATSBY_TOKEN}`,
      "content-type": "application/json",
    },
  })
  return response
}

export const updateWaterTake = async (_userId, _day, _consumed) => {
  const url = `https://esteekeyapi.com/${_userId}/water-consumption`
  console.log({ _userId, _day, _consumed })
  const response = await axios({
    method: "POST",
    url: url,
    data: {
      day: _day,
      waterConsumed: _consumed,
    },
  })

  return response
}

export const getTaskList = async () => {
  const response = await axios({
    method: "GET",
    url: "https://esteekeyapi.com/daily-task-list",
  })

  return response
}

export const updateUserTaskList = async (_userId, _data) => {
  const url = `https://esteekeyapi.com/${_userId}/task-list`
  const response = await axios({
    method: "POST",
    url: url,
    data: _data,
  })

  return response
}

export const updateUserMeasurment = async (_userId, _data, _type) => {
  let url = `https://esteekeyapi.com/${_userId}/`

  if (_type === "weight") url += "weight2"
  else if (_type === "glutes") url += "glutes"
  else url += "waist"

  const response = await axios({
    method: "POST",
    url: url,
    data: _data,
  })

  return response
}
