import Moment from "moment"
import { extendMoment } from "moment-range"

import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz"

const moment = extendMoment(Moment)

export const getCurrentDate = _date => {
  let now = moment(_date)

  return {
    now,
    to_date: now.toDate(),
    to_string: now.toString(),
    prettier: prettierFormater(now),
  }
}

export const getNextDates = (_date, _day) => {
  let toDate = moment(_date).add(7, "days")
  let range = moment().range(_date, toDate)
  let array = Array.from(range.by("days"))

  let nextDates = []

  array.forEach((date, i) => {
    nextDates.push({
      day_id: i - 1,
      day: _day + i,
      now: date,
      to_date: date.toDate(),
      to_string: date.toString(),
      prettier: prettierFormater(date),
    })
  })

  return nextDates
}

// export const getNextDates = (_date, _day, _day_id) => {
//   let toDate = moment(_date).add(7, "days")
//   let range = moment().range(_date, toDate)
//   let array = Array.from(range.by("days"))

//   let nextDates = []
//   let mealDay = 0
//   let count = 0

//   array.forEach((date, i) => {
//     mealDay = _day_id + i
//     nextDates.push({
//       day_id: mealDay <= 6 ? mealDay : count++,
//       day: _day + i,
//       now: date,
//       to_date: date.toDate(),
//       to_string: date.toString(),
//       prettier: prettierFormater(date),
//     })
//   })

//   return nextDates
// }

export const getPrevDates = (_date, _day) => {
  let fromDate = moment(_date).subtract(7, "days")
  let range = moment().range(fromDate, _date)
  let array = Array.from(range.by("days"))
  let day = 0

  if (_day <= 2) day = 0
  else if (_day === 3) day = 6
  else {
    day = 6
    for (let i = 4; i <= _day; i++) day += 7

    day += 1
  }

  // switch (_day) {
  //   case 1:
  //     _day = 0
  //     break
  //   case 2:
  //     _day = 0
  //     break
  //   case 3:
  //     _day = 6
  //     break
  //   case 4:
  //     _day = 13
  //     break
  //   case 5:
  //     _day = 20
  //     break
  //   case 6:
  //     _day = 27
  //     break
  //   case 7:
  //     _day = 34
  //     break
  //   case 8:
  //     _day = 41
  //     break
  //   case 9:
  //     _day = 48
  //     break
  //   case 10:
  //     _day = 55
  //     break
  //   case 11:
  //     _day = 62
  //     break
  //   case 12:
  //     _day = 69
  //     break
  //   case 13:
  //     _day = 76
  //     break
  //   case 14:
  //     _day = 83
  //     break
  //   case 15:
  //     _day = 90
  //     break
  //   case 16:
  //     _day = 97
  //     break
  //   case 17:
  //     _day = 104
  //     break
  //   case 18:
  //     _day = 111
  //     break
  //   case 19:
  //     _day = 118
  //     break
  //   case 20:
  //     _day = 125
  //     break
  //   case 21:
  //     _day = 132
  //     break
  //   case 22:
  //     _day = 139
  //     break
  //   case 23:
  //     _day = 146
  //     break
  //   case 24:
  //     _day = 153
  //     break
  //   case 25:
  //     _day = 160
  //     break
  //   case 26:
  //     _day = 167
  //     break
  // }

  let prevDates = []

  array.forEach((date, i) => {
    prevDates.push({
      day_id: i,
      day: day + i,
      now: date,
      to_date: date.toDate(),
      to_string: date.toString(),
      prettier: prettierFormater(date),
    })
  })

  return prevDates
}

// export const getPrevDates = (_date, _day, _day_id) => {
//   let fromDate = moment(_date).subtract(7, "days")
//   let range = moment().range(fromDate, _date)
//   let array = Array.from(range.by("days"))

//   switch (_day) {
//     case 1:
//       _day = 0
//       break
//     case 2:
//       _day = 0
//       break
//     case 3:
//       _day = 6
//       break
//     case 4:
//       _day = 13
//       break
//     case 5:
//       _day = 20
//       break
//     case 6:
//       _day = 27
//       break
//   }

//   let prevDates = []
//   let mealDay = 0
//   let count = 0

//   array.forEach((date, i) => {
//     mealDay = _day_id + i
//     prevDates.push({
//       day_id: mealDay <= 6 ? mealDay : count++,
//       day: _day + i,
//       now: date,
//       to_date: date.toDate(),
//       to_string: date.toString(),
//       prettier: prettierFormater(date),
//     })
//   })

//   return prevDates
// }

export const getInitialList = _date => {
  let current = getCurrentDate(_date)
  // let weekStart = current.now.clone().startOf("isoWeek")
  let nextDates = getNextDates(current.now)
  // let nextDates = getNextDates(weekStart)

  // let list = [...nextDates.slice(0, nextDates.length - 1)]

  let list = [current, ...nextDates.slice(1, nextDates.length - 1)]
  return list.map((item, i) => ({ ...item, day: i, day_id: i }))

  // let count = 0
  // return list.map(item => {
  //   let diff = item.now.diff(current.now)
  //   if (diff >= 0) {
  //     let newItem = { ...item, day: count, day_id: count }
  //     count++
  //     return newItem
  //   } else {
  //     return { ...item, day: diff, day_id: diff }
  //   }
  // })
}

export const checkIfSame = (_date1, _date2) => {
  if (!_date1 || !_date2) return false

  return moment(_date1.format("YYYY-MM-DD")).isSame(_date2.format("YYYY-MM-DD"))
}

export const getDiff = (_start, _curr) => {
  let given = moment(_start, "YYYY-MM-DD HH:mm a")
  let current = moment(_curr, "YYYY-MM-DD HH:mm a")

  return current.diff(given, "days")
}

export const getDiffByDay = _date => {
  // let moscowTime = toMoscowTime(_date)
  // let utc = new Date(_date).toISOString()
  // let parseDate = moment().utc(_date).format()
  // let local = moment(parseDate).local().toDate()

  let current = new Date()
  let given = new Date(_date)

  if (given.getTime() >= current.getTime()) {
    return -1
  } else {
    return 1
  }

  // return current.diff(given, "hours", true)
}

export const toMoscowTime = _date => {
  let current = new Date(_date)
  const timeZone = "Europe/Moscow"

  const zonedDate = utcToZonedTime(current, timeZone)

  const pattern = "yyyy-MM-dd h:mm a"

  const output = format(zonedDate, pattern, { timeZone: "Europe/Moscow" })

  return output
}

export const toMoment = _data => {
  if (!_data) return ""

  return moment(_data, "YYYY-MM-DD").format("YYYY-MM-DD")
}

const prettierFormater = _date => {
  return {
    day_name: moment(_date).format("dddd").substring(0, 3),
    day: moment(_date).format("DD"),
    startDate: moment(_date).format("YYYY-MM-DD"),
    endDate: moment(_date).add(7, "days").format("YYYY-MM-DD"),
    month: moment(_date).format("MMM"),
  }
}

export const getCurrentWeek = _startDate => {
  let now = getCurrentDate()
  let currDay = getDiff(_startDate, now.prettier.startDate)
  // console.log("new diff", { currDay, date: now.prettier.startDate })

  let end = 6
  let week = 1
  let mealDay = currDay
  let count = 1

  while (end < currDay) {
    week++
    mealDay = Math.abs(currDay - 7 * count)
    count++
    end += 7
  }

  // mealDay -= 1
  // currDay -= 1

  // if (currDay <= 6) {
  //   week = 1
  // } else if (currDay > 6 && currDay <= 13) {
  //   mealDay = Math.abs(currDay - 7)
  //   week = 2
  // } else if (currDay > 13 && currDay <= 20) {
  //   mealDay = Math.abs(currDay - 14)
  //   week = 3
  // } else if (currDay > 20 && currDay <= 27) {
  //   mealDay = Math.abs(currDay - 21)
  //   week = 4
  // } else if (currDay > 27 && currDay <= 34) {
  //   mealDay = Math.abs(currDay - 28)
  //   week = 5
  // } else if (currDay > 34 && currDay <= 41) {
  //   mealDay = Math.abs(currDay - 35)
  //   week = 6
  // } else if (currDay > 41 && currDay <= 48) {
  //   mealDay = Math.abs(currDay - 42)
  //   week = 7
  // } else if (currDay > 48 && currDay <= 55) {
  //   mealDay = Math.abs(currDay - 49)
  //   week = 8
  // } else if (currDay > 55 && currDay <= 62) {
  //   mealDay = Math.abs(currDay - 56)
  //   week = 9
  // } else if (currDay > 62 && currDay <= 69) {
  //   mealDay = Math.abs(currDay - 63)
  //   week = 10
  // } else if (currDay > 69 && currDay <= 76) {
  //   mealDay = Math.abs(currDay - 70)
  //   week = 11
  // } else if (currDay > 76 && currDay <= 83) {
  //   mealDay = Math.abs(currDay - 77)
  //   week = 12
  // } else if (currDay > 83 && currDay <= 90) {
  //   mealDay = Math.abs(currDay - 84)
  //   week = 13
  // } else if (currDay > 90 && currDay <= 97) {
  //   mealDay = Math.abs(currDay - 91)
  //   week = 14
  // } else if (currDay > 97 && currDay <= 104) {
  //   mealDay = Math.abs(currDay - 98)
  //   week = 15
  // } else if (currDay > 104 && currDay <= 111) {
  //   mealDay = Math.abs(currDay - 105)
  //   week = 16
  // } else if (currDay > 111 && currDay <= 118) {
  //   mealDay = Math.abs(currDay - 112)
  //   week = 17
  // } else if (currDay > 118 && currDay <= 125) {
  //   mealDay = Math.abs(currDay - 119)
  //   week = 18
  // } else if (currDay > 125 && currDay <= 132) {
  //   mealDay = Math.abs(currDay - 126)
  //   week = 19
  // } else if (currDay > 132 && currDay <= 139) {
  //   mealDay = Math.abs(currDay - 133)
  //   week = 20
  // } else if (currDay > 139 && currDay <= 146) {
  //   mealDay = Math.abs(currDay - 140)
  //   week = 21
  // } else if (currDay > 146 && currDay <= 153) {
  //   mealDay = Math.abs(currDay - 147)
  //   week = 22
  // } else if (currDay > 153 && currDay <= 160) {
  //   mealDay = Math.abs(currDay - 154)
  //   week = 23
  // } else if (currDay > 160 && currDay <= 167) {
  //   mealDay = Math.abs(currDay - 161)
  //   week = 24
  // } else if (currDay > 167 && currDay <= 174) {
  //   mealDay = Math.abs(currDay - 168)
  //   week = 25
  // } else if (currDay > 174 && currDay <= 181) {
  //   mealDay = Math.abs(currDay - 175)
  //   week = 26
  // }

  week = 2

  return {
    week,
    currDay,
    mealDay,
  }
}

export const getCurrWeekDays = (currDay, _startDate) => {
  let end = 6
  let currWeek = getInitialList(_startDate)

  while (end < currDay && currDay <= 224) {
    currWeek = getNextWeek(currWeek)
    end += 7
  }

  return currWeek

  // let firstWeek = getInitialList(_startDate)
  // let secondWeek = getNextWeek(firstWeek)
  // let thirdWeek = getNextWeek(secondWeek)
  // let fourthWeek = getNextWeek(thirdWeek)
  // let fifthWeek = getNextWeek(fourthWeek)
  // let sixthWeek = getNextWeek(fifthWeek)
  // let seventhWeek = getNextWeek(sixthWeek)
  // let eighthWeek = getNextWeek(seventhWeek)
  // let ninthWeek = getNextWeek(eighthWeek)
  // let tenthWeek = getNextWeek(ninthWeek)
  // let eleventhWeek = getNextWeek(tenthWeek)
  // let twelvethWeek = getNextWeek(eleventhWeek)
  // let thirteenWeek = getNextWeek(twelvethWeek)
  // let week14 = getNextWeek(thirteenWeek)
  // let week15 = getNextWeek(week14)
  // let week16 = getNextWeek(week15)
  // let week17 = getNextWeek(week16)
  // let week18 = getNextWeek(week17)
  // let week19 = getNextWeek(week18)
  // let week20 = getNextWeek(week19)
  // let week21 = getNextWeek(week20)
  // let week22 = getNextWeek(week21)
  // let week23 = getNextWeek(week22)
  // let week24 = getNextWeek(week23)
  // let week25 = getNextWeek(week24)
  // let week26 = getNextWeek(week25)

  // if (currDay <= 6) {
  //   currWeek = firstWeek
  // } else if (currDay > 6 && currDay <= 13) {
  //   currWeek = secondWeek
  // } else if (currDay > 13 && currDay <= 20) {
  //   currWeek = thirdWeek
  // } else if (currDay > 20 && currDay <= 27) {
  //   currWeek = fourthWeek
  // } else if (currDay > 27 && currDay <= 34) {
  //   currWeek = fifthWeek
  // } else if (currDay > 34 && currDay <= 41) {
  //   currWeek = sixthWeek
  // } else if (currDay > 41 && currDay <= 48) {
  //   currWeek = seventhWeek
  // } else if (currDay > 48 && currDay <= 55) {
  //   currWeek = eighthWeek
  // } else if (currDay > 55 && currDay <= 62) {
  //   currWeek = ninthWeek
  // } else if (currDay > 62 && currDay <= 69) {
  //   currWeek = tenthWeek
  // } else if (currDay > 69 && currDay <= 76) {
  //   currWeek = eleventhWeek
  // } else if (currDay > 76 && currDay <= 83) {
  //   currWeek = twelvethWeek
  // } else if (currDay > 83 && currDay <= 90) {
  //   currWeek = thirteenWeek
  // } else if (currDay > 90 && currDay <= 97) {
  //   currWeek = week14
  // } else if (currDay > 97 && currDay <= 104) {
  //   currWeek = week15
  // } else if (currDay > 104 && currDay <= 111) {
  //   currWeek = week16
  // } else if (currDay > 111 && currDay <= 118) {
  //   currWeek = week17
  // } else if (currDay > 118 && currDay <= 125) {
  //   currWeek = week18
  // } else if (currDay > 125 && currDay <= 132) {
  //   currWeek = week19
  // } else if (currDay > 132 && currDay <= 139) {
  //   currWeek = week20
  // } else if (currDay > 139 && currDay <= 146) {
  //   currWeek = week21
  // } else if (currDay > 146 && currDay <= 153) {
  //   currWeek = week22
  // } else if (currDay > 153 && currDay <= 160) {
  //   currWeek = week23
  // } else if (currDay > 160 && currDay <= 167) {
  //   currWeek = week24
  // } else if (currDay > 167 && currDay <= 174) {
  //   currWeek = week25
  // } else if (currDay > 174 && currDay <= 181) {
  //   currWeek = week26
  // }

  // return currWeek
}

const getNextWeek = _dates => {
  let lastIndex = _dates[_dates.length - 1]
  let nextDates = getNextDates(lastIndex?.now, lastIndex?.day)
  return nextDates.slice(1, nextDates.length)
}
