import React, { createContext, useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { toast } from "react-toastify"

// utils
import { getUserData } from "../utils/requests"
import { getDiffByDay, toMoscowTime } from "../utils/dateHelper"

//Create Context
const UserContext = createContext()

const startTime = "2022-08-15"

export const UserProvider = ({ children }) => {
  const [userMetaData, setUserMetaData] = useState(null)
  const [isAccess, setIsAccess] = useState(false)
  // const [isAccess, setIsAccess] = useState(true)
  const [isRegenerate, setIsRegenerate] = useState(false)

  const { user, isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    if (user && isAuthenticated) {
      fetchUserData()
    }
  }, [user, isAuthenticated])

  const fetchUserData = async () => {
    try {
      const { data } = await getUserData(user.sub)

      setUserMetaData(data)
      toast.success(`Welcome, ${data.nickname}!`)

      if (data.app_metadata?.plan !== undefined) {
        if (data.app_metadata?.plan === "admin") {
          setIsAccess(true)
        } else {
          let expire_date = data.app_metadata?.expiration_date
          let purchase_data = data.app_metadata?.purchased_date

          if (
            data.app_metadata?.plan !== "none" &&
            getDiffByDay(purchase_data) > 0 &&
            getDiffByDay(expire_date) < 0
          )
            setIsAccess(true)
        }
      }
    } catch (err) {
      console.log("user auth line 59", err.message)
    }
  }

  const checkRegenerate = _check => {
    setUserMetaData(null)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        userMetaData,
        isAuthenticated: isAuthenticated,
        isAccess,
        isLoading: isLoading,
        setUserMetaData,
        isRegenerate,
        checkRegenerate,
        startTime,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
