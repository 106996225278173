import moment from "moment"
import keys from "../config/keys"

export const getTotalDuration = _duration => {
  let durationMinutes = Math.floor(_duration / 60)
  let durationSeconds = Math.floor(_duration - durationMinutes * 60)

  if (durationSeconds < 10) {
    durationSeconds = "0" + durationSeconds
  }

  if (durationMinutes < 10) {
    durationMinutes = "0" + durationMinutes
  }

  return "" + durationMinutes + ":" + durationSeconds
}

export const getCurrentTime = _time => {
  let currentMinutes = Math.floor(_time / 60)
  let currentSeconds = Math.floor(_time - currentMinutes * 60)

  if (currentSeconds < 10) {
    currentSeconds = "0" + currentSeconds
  }

  if (currentMinutes < 10) {
    currentMinutes = "0" + currentMinutes
  }

  return "" + currentMinutes + ":" + currentSeconds
}

export const changeUnit = _list => {
  let newList = _list.map(item => ({
    ...item,
    ...swapUnit(item?.unit?.name || item.unit, item?.amount || item.quantity),
  }))

  return newList
}

export const swapUnit = (_unit, _quantity) => {
  if (_unit.toLowerCase() === "kg" || _unit.toLowerCase() === "kilograms") {
    return {
      unit: "grams",
      amount: _quantity * 1000,
    }
  } else if (_unit.toLowerCase() === "g" || _unit.toLowerCase() === "grams") {
    return {
      unit: "kilograms",
      amount: _quantity / 1000,
    }
  } else if (_unit.toLowerCase() === "L" || _unit.toLowerCase() === "litres") {
    return {
      unit: "millilitres",
      amount: _quantity * 1000,
    }
  } else if (
    _unit.toLowerCase() === "mL" ||
    _unit.toLowerCase() === "millilitres"
  ) {
    return {
      unit: "litres",
      amount: _quantity / 1000,
    }
  }
}

export const getTotalCalories = _list => {
  return _list.reduce((acc, curr) => {
    return acc + curr.unit
  }, 0)
}

export const getPurchaseDate = () => {
  return moment().format("YYYY-DD-MM h:mm a")
}

export const getExpireDate = _timespan => {
  return moment().add(_timespan, "M").format("YYYY-DD-MM h:mm a")
}

export const getPlan = _str => {
  let id = _str.substring(1, _str.length)
  console.log(id)
  if (id === keys.standard_plan_id) return "standard"
  else if (id === keys.premium_plan_id) return "premium"
  else if (id === keys.vip_plan_id) return "vip"
  else return "none"
}

export const getTimeRemaining = endtime => {
  const total = Date.parse(endtime) - Date.parse(new Date())
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  }
}

export const calculateCalories = _data => {
  let weight = parseInt(_data.weight)
  let height = parseInt(_data.height)
  let age = parseInt(_data.age)

  let sex = _data.gender === "male" ? 5 : -161
  let goal = goalValue(_data.goal.valueEN)

  //intermediate calorie steps
  let BMR = 0
  let TDEE = 0

  //default activity multiplier
  let intensity = 1.465

  //the final output of calorie calculations
  let TotalCals = 0

  if (_data?.isJob > 0) {
    intensity = 1.7
  }

  //Standard BMR calculation
  BMR = Math.round(10 * weight + 6.25 * height - 5 * age + sex)

  //TDEE calculation
  TDEE = Math.round(BMR * intensity)
  TotalCals = Math.round(TDEE + (TDEE * goal) / 100)

  return TotalCals
}

export const goalValue = _value => {
  return _value === "gainWeight" ? 10 : _value === "loseWeight" ? -20 : 0
}

export const convertUnit = (_unit, h, w) => {
  let height = Number(h)
  let weight = Number(w)

  if (_unit === "m/kg") {
    height = height * 100
  } else if (_unit === "m/pounds") {
    height = height * 100
    weight = weight / 2.205
  }

  return {
    height,
    weight,
  }
}

export const convertToFraction = _amount => {
  var len = _amount.toString().length - 2

  var denominator = Math.pow(10, len)
  var numerator = _amount * denominator

  var divisor = gcd(numerator, denominator)

  numerator /= divisor
  denominator /= divisor

  return Math.floor(numerator) + "/" + Math.floor(denominator)
}

const gcd = (a, b) => {
  if (b < 0.0000001) return a

  return gcd(b, Math.floor(a % b))
}

export const addOrder = _mealTime => {
  if (_mealTime?.name || _mealTime === "Breakfast") return 1
  if (_mealTime?.name || _mealTime === "Fruit") return 2
  if (_mealTime?.name || _mealTime === "Lunch") return 3
  if (_mealTime?.name || _mealTime === "Dinner") return 4
  if (_mealTime?.name || _mealTime === "Snack") return 5
  if (_mealTime?.name || _mealTime === "Protein") return 6
}

export const sortMealPlan = (_cal, _list) => {
  let mulCalList = _list.map(item => ({
    ...item,
    cal_range: multiplyCalories(item.calories),
  }))

  mulCalList = mulCalList.map(item => ({
    ...item,
    servings: closestCalories(item.cal_range, _cal),
  }))

  return mulCalList.map(item => ({
    ...item,
    calories: Math.round(item.calories * item.servings),
    carbs: Math.round(item.carbs * item.servings),
    protein: Math.round(item.protein * item.servings),
    fat: Math.round(item.fat * item.servings),
  }))
}

const multiplyCalories = _cal => {
  let range = [0.5, 1, 1.5, 2, 2.5, 3]

  return range.map(item => item * _cal)
}

const closestCalories = (_cals, _goal) => {
  let range = [0.5, 1, 1.5, 2, 2.5, 3]

  let closest = _cals.reduce(function (prev, curr) {
    return Math.abs(curr - _goal) < Math.abs(prev - _goal) ? curr : prev
  })

  return range[_cals.indexOf(closest)]
}

export const translateMonth = _mon => {
  switch (_mon) {
    case "Jan":
      return "Янв"
    case "Feb":
      return "Фев"
    case "Mar":
      return "Мар"
    case "Apr":
      return "Апр"
    case "May":
      return "May"
    case "Jun":
      return "Июн"
    case "Jul":
      return "Июл"
    case "Aug":
      return "Авг"
    case "Sep":
      return "Сен"
    case "Oct":
      return "Окт"
    case "Nov":
      return "Ноя"
    case "Dec":
      return "Дек"
  }
}

export const translateWeekday = _day => {
  switch (_day) {
    case "Sun":
      return "Вс"
    case "Mon":
      return "Пн"
    case "Tue":
      return "Вт"
    case "Wed":
      return "Ср"
    case "Thu":
      return "Чт"
    case "Fri":
      return "Пт"
    case "Sat":
      return "Сб"
  }
}

export function getWeeklyStats(_list) {
  const week1 = getLatestValue(_list, -1, 6)
  const week2 = getLatestValue(_list, 6, 13)
  const week3 = getLatestValue(_list, 13, 20)
  const week4 = getLatestValue(_list, 20, 27)
  const week5 = getLatestValue(_list, 27, 34)
  const week6 = getLatestValue(_list, 34, 41)

  return [week1, week2, week3, week4, week5, week6]
}

function getMean(_list, _min, _max) {
  const filter = _list.filter(item => item.day > _min && item.day <= _max)

  if (filter.length === 0) return 0

  let sum = 0
  filter.forEach(item => (sum += item.reading))

  return sum / filter.length
}

function getLatestValue(_list, _min, _max) {
  const filter = _list.filter(item => item.day > _min && item.day <= _max)

  if (filter.length === 0) return 0

  filter.sort((a, b) => b.day - a.day)

  return filter[0].reading
}
